// universal form widgets

require("jquery-ui");           // for $().sortable

// get value from a Cleave input - returns a currency object
// that can be converted to a string with .value
// or an integer with .intValue (this will be in cents)
function getCleave(input) {
  return currency($(input).val());
}
document.getCleave = getCleave;

// For all data-cleave-2dp inputs:
// force set 2 decimal places
// force min/max if data-cleave-min or data cleave-max are defined
function applyCleave(scope = '') {
  $(scope + '[data-cleave-2dp]').each(function (index, e) {
    let el = $(e);
    let cleaveMin = el.data('cleave-min');
    let cleaveMax = el.data('cleave-max');
    let cleave = new Cleave(e, {
      numeral: true,
      numeralDecimalScale: 2,
      onValueChanged: function (ee) {
        if (cleaveMin || cleaveMax) {
          if (typeof cleaveMin == 'object') {
            console.log('Error: cleaveMin should be a number');
          }

          if (typeof cleaveMax == 'object') {
            console.log('Error: cleaveMax should be a number');
          }

          let val = parseFloat(ee.target.rawValue);
          if (cleaveMax != undefined && val != undefined && (val > cleaveMax)) {
            e.setCustomValidity('This value cannot be above ' + cleaveMax);
          } else if (cleaveMin != undefined && val != undefined && (val < cleaveMin)) {
            e.setCustomValidity('This value cannot be below ' + cleaveMin);
          } else {
            e.setCustomValidity('');
          }
        }
      }
    });
  });
}

// so we can call this when modals are sent
document.applyCleave = applyCleave;


document.addEventListener("turbolinks:load", function() {

  // jQuery-UI Sortable support
  // To use:
  // The container must have {data: {sortable_url: url}}, where url is the PUT url
  // Each item must have {data: {sortable_item_id: x}},
  // - which is the id of the item that will be sent back to the server.
  //
  // The server will be sent:
  // moved_item_id: the id of the item that was moved
  // moved_after_id: the id of the item that the moved item was put after (or nil if first)
  // new_position: the new position of the moved item, zero indexed

  $('[data-sortable-url]').sortable({
    update: function (event, ui) {
      let container = ui.item.closest('[data-sortable-url]');
      let newPosition = ui.item.index();
      let movedItemId = ui.item.data('sortable-item-id');
      let movedAfterItem = container.children()[newPosition - 1];
      let movedAfterId = $(movedAfterItem).data('sortable-item-id');

      let url = container.data('sortable-url');

      $.ajax({
        method: 'PUT',
        data: {
          new_position: newPosition,
          moved_item_id: movedItemId,
          moved_after_id: movedAfterId
        },
        url: url
      });
    }
  });

  // Modal close buttons
  $(document.body).on("click", "[data-modal-close]", function (e) {
    let id = $(e.target).data('modal-close');
    let state = $(".modal-state#" + id + ":checked");
    let form = state.closest('.modal').find('form');

    // if a form has areYouSure and it's marked dirty, confirm close
    if (form.hasClass('dirty')) {
      if (!confirm("Your unsaved changes will be lost, are you sure?")) {
        e.preventDefault();
        return;
      }
    }

    state.prop("checked", false);

    // reset dirty tracking so we don't get a warning when we navigate
    form.trigger('reinitialize.areYouSure');

    // Should select2 be destroyed on close? It doesn't seem to need this.
    // console.log('destroying select2 in close!', form.find('select[data-select2-id]'));
    // form.find('select[data-select2-id]').select2('destroy');

    e.preventDefault();
  });

  // call it on the base page
  applyCleave();

  // Allows the user to pick which field to lock, of three choices
  // And auto-balances the other two fields

  function lockPicker(scope = '') {
    $(scope + '[data-lockpicker]>div:first-child').on('click', function(e) {
      let target = $(e.currentTarget);
      let indexToLock = target.closest('[data-lockpicker]').data('lockpicker');

      for(let index = 0; index < 3; index++) {
        if(index === indexToLock) {
          $(scope + '[data-lockpicker=' + index + '] input').addClass('tooltip-hover');

          $(scope + '[data-lockpicker=' + index + '] svg').removeClass('hidden');
          $(scope + '[data-lockpicker=' + index + '] svg.fa-lock-open').addClass('hidden');

          $(scope + '[data-lockpicker=' + index + ']').parent().find('input').prop('readonly', true);
        } else {
          $(scope + '[data-lockpicker=' + index + '] input').removeClass('tooltip-hover');

          $(scope + '[data-lockpicker=' + index + '] svg').removeClass('hidden');
          $(scope + '[data-lockpicker=' + index + '] svg.fa-lock').addClass('hidden');

          $(scope + '[data-lockpicker=' + index + ']').parent().find('input').prop('readonly', false);
        }
      }
    });

    // When a field changes, calculate the partner field's value
    // Cost to Depreciate - Book Value = Depreciation to Date

    $(scope + '[data-lockpicker] input').on('keyup change', function(e) {
      let changingField = $(e.target);
      let changingFieldId = changingField.closest('[data-lockpicker]').data('lockpicker');

      let form = changingField.closest('form');

      // find target field: ie. not locked and not being changed
      let targetFieldId = null;
      form.find('[data-lockpicker] input').each(function(i, e) {
        let thisFieldId = $(e).closest('[data-lockpicker]').data('lockpicker');
        if((thisFieldId !== changingFieldId) && !$(e).prop('readonly')) {
          targetFieldId = thisFieldId;
        }
      });

      let a = form.find('[data-lockpicker=0] input');
      let b = form.find('[data-lockpicker=1] input');
      let c = form.find('[data-lockpicker=2] input');

      let aVal = currency($(a).val());
      let bVal = currency($(b).val());
      let cVal = currency($(c).val());

      // calculate result of change

      // a - b = c
      // PC - DTD = BV

      if(targetFieldId === 0) {
        // if target is a: a = b + c
        a.val(bVal.add(cVal));
        applyCleave(scope + '[data-lockpicker=0] ')
      } else if(targetFieldId === 1) {
        // if target is b: b = a - c
        b.val(aVal.subtract(cVal));
        applyCleave(scope + '[data-lockpicker=1] ')
      } else if(targetFieldId === 2) {
        // if target is c: c = a - b
        c.val(aVal.subtract(bVal));
        applyCleave(scope + '[data-lockpicker=2] ')
      }

    });
  }

  // so we can call this when modals are sent
  document.lockPicker = lockPicker;

  // activate tooltips
  window.isotip.init({html: true});

  // add 'waiting' CSS after valid form submit
  $('[data-click-wait]').on('submit', function () {
    $('body').addClass('waiting');
  });

  // Reveal controls when a button is clicked. If the button has data-target, use that
  // as an #id to get the target area to reveal, otherwise reveal the .reveal-hidden
  // class.

  function getTarget(e) {
    e.preventDefault();
    let revealTarget;
    revealTarget = $(e.target).data('target');
    if (revealTarget) {
      return $("#" + revealTarget);
    } else {
      return $('.reveal-hidden');
    }
  }

  $('button.reveal').click(function(e) {
    return getTarget(e).toggle(100);
  });

  $('button.xls').click(function(e) {
    return getTarget(e).hide(100);
  });

  $('button.csv').click(function(e) {
    return getTarget(e).hide(100);
  });

  $('button.pdf').click(function(e) {
    return getTarget(e).hide(100);
  });

  $('button.send').click(function(e) {
    return getTarget(e).hide(100);
  });

  // for checkboxes
  return $('body').on('change', 'input.reveal', function(e) {
    return getTarget(e).toggle(100);
  });
});


