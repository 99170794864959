let checkDepreciationStatusHandle = null;
document.addEventListener("turbolinks:load", function() {

  // Poll and update batch operation status

  if($('[data-batch-status]').length > 0) {
    let checkSync = function () {
      $.getScript(
        Routes.get_status_admin_depreciation_index_path(),
        function () {
          checkDepreciationStatusHandle = setTimeout(checkSync, 7000);
        });
    };

    if ($('[data-batch-status]').length > 0) {
      checkDepreciationStatusHandle = setTimeout(checkSync, 7000);
    }
  } else
  {
    clearTimeout(checkDepreciationStatusHandle);
  }
});
