document.addEventListener("turbolinks:load", function() {
  function sumColumns(myColumn) {
    let sum = 0
    $(`[data-sum-column=${myColumn}]`).each(function() {
      sum += document.getCleave(this).intValue;
    });
    $(`[data-column-sum=${myColumn}]`).val(currency(sum).divide(100));
    document.applyCleave();
  }

  if($('[data-sum-column]').length > 0) {
    let column = 1;

    while($(`[data-sum-column=${column}]`).length > 0) {
      $(`[data-sum-column=${column}]`).on('keyup', function() {
        sumColumns($(this).data('sumColumn'));
      });

      // Immediately sum the column on page load
      sumColumns(column);
      column += 1;
    }
  }

  // Set the selected year below the titles for remaining spend and next year estimate
  function setSelectedYear(selected) {
    $('[data-remaining-spend]').html(selected);
    let nextYear = parseInt(selected) + 1;

    //if selected has a slash in it, add a slash to nextYear
    if (selected.includes('/')) {
      nextYear = `${nextYear}/${nextYear + 1}`
    }

    $('[data-next-year-estimate]').html(nextYear);
  }

  if($('[data-projection-fy]').length > 0) {
    $('[data-projection-fy]').on('change', function() {
      let selected = $(this).val();
      setSelectedYear(selected);
    });

    setSelectedYear($('[data-projection-fy]').val());
  }
});
