document.addEventListener("turbolinks:load", function() {
  $('[data-select-organisation]').select2();
  $('[data-select-two]').select2();

  $('[data-select-organisation]').change((function(_this) {
    return function(e) {
      let organisation_id;
      organisation_id = $(e.target).val();
      window.location.href = location.protocol + '//' + location.host + '?' + `organisation_id=${organisation_id}`;
    };
  })(this));
});
