
document.addEventListener("turbolinks:load", function() {
  let scope = '[data-asset-history] ';

  // show hidden history when the button is clicked
  $(document.body).on('click', scope + '[data-show-hidden]', function(e) {
    let tbody = $(e.target).closest('tbody');
    tbody.find('tr').removeClass('hidden');
    tbody.find('[data-show-hidden]').closest('tr').addClass('hidden');
  });

  // hide the section again
  $(document.body).on('click', scope + '[data-hide-above]', function(e) {
    let tbody = $(e.target).closest('tbody');
    tbody.find('tr').addClass('hidden');
    tbody.find('[data-show-hidden]').closest('tr').removeClass('hidden');
  });
});
