document.addEventListener("turbolinks:load", function() {
  if($('[data-sign-up-form]').length > 0) {

    $('[data-hide-form]').click(function(){
      $('[data-sign-up-form]').hide();
      $('input').removeAttr('required');
    });

    $('[data-show-form]').click(function(){
      $('[data-sign-up-form]').show();
      $('input').attr('required', 'required');
    });
  }
});

