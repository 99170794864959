// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import * as Routes from 'routes'
window.Routes = Routes;

import 'header'
import 'depreciation'
import 'depreciation_projection'
import 'assets'
import 'asset_history'
import 'sign_up'

import "@fortawesome/fontawesome-free/js/all"
import "jquery.are-you-sure/jquery.are-you-sure"

import Dropzone from "dropzone"
window.Dropzone = Dropzone;       // not strictly needed since it attaches to jQuery

import Cleave from 'cleave.js'
window.Cleave = Cleave;

import isotip from 'isotip'
window.isotip = isotip;

import currency from 'currency.js'
window.currency = currency;

import bigDecimal from "js-big-decimal";
window.bigDecimal = bigDecimal;

Rails.start();
Turbolinks.start();
ActiveStorage.start();

global.$ = require("jquery");
global.jQuery = require("jquery");

// Small date calculation/formatting library
import { format, addYears, parse } from 'date-fns'
window.dateFnsFormat = format;
window.dateFnsAddYears = addYears;
window.dateFnsParse = parse;

// Note that this does not work:
// import format from 'date-fns'

// But this does:
// import { format } from 'date-fns'

// And this does:
// import addYears from 'date-fns'


require("./shared.js");
